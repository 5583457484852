//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET } from "@/core/services/store/request.module";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
export default {
  name: "bug-detail",
  data: function data() {
    return {
      bugId: null,
      bugDetail: new Object({})
    };
  },
  components: {
    DetailTemplate: DetailTemplate,
    FileTemplate: FileTemplate
  },
  methods: {
    getBugDetail: function getBugDetail() {
      var _this = this;

      _this.$store.dispatch(GET, {
        url: "bug/" + _this.bugId
      }).then(function (_ref) {
        var data = _ref.data;
        _this.bugDetail = data;
      }).catch(function (error) {
        _this.logError(error);
      });
    }
  },
  computed: {
    bugDetailTitle: function bugDetailTitle() {
      return "hello world";
    }
  },
  mounted: function mounted() {
    this.getBugDetail();
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Bug",
      route: "bug"
    }]);
  },
  created: function created() {
    var _this = this;

    _this.bugId = _this.lodash.toSafeInteger(_this.$route.params.id);

    if (!_this.bugId || _this.bugId <= 0) {
      _this.$router.go(-1);
    }
  }
};